<template>
    <div class="body-wrap">
        <Header />
        <main class="page">
            <h1 class="text-white text-center" >Not found</h1>
            <p class="text-white text-center">This page was not found, please go to home</p>
        </main>
    </div>
</template>
<script>
import Header from '../components/Header.vue';

export default {
    name: 'NotFound',
    components: {
        Header
    }
}
</script>