<template>
  <div class="body-wrap">
    <Header />
    <main>
      <section class="hero">
    <div class="container">
        <div class="hero-inner">
            <div class="hero-copy">
                <h1 class="hero-title mt-0">Reach home, IoT</h1>
                <p class="hero-paragraph">
                    Conectamos dispositivos con personas, con Reach Home tu puedes
                    controlar tus dispositivos desde una sola aplicacion en tiempo
                    real.
                </p>
                <div class="hero-cta">
                    <a class="button button-primary" href="#">App store</a><a class="button" href="#"> PlayStore</a>
                </div>
            </div>
            <div class="hero-figure anime-element">
                <img class="phone-top" src="images/iphone.png" />
                <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                    <rect width="528" height="396" style="fill: transparent" />
                </svg>
                <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
                <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
                <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div>
                <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
                <div class="hero-figure-box hero-figure-box-05"></div>
                <div class="hero-figure-box hero-figure-box-06"></div>
                <div class="hero-figure-box hero-figure-box-07"></div>
                <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
                <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
                <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
            </div>
        </div>
    </div>
</section>

<section class="features section">
    <div class="container">
        <div class="features-inner section-inner has-bottom-divider">
            <div class="features-wrap">
                <div class="feature text-center is-revealing">
                    <div class="feature-inner">
                        <div class="feature-icon">
                            <i class="bi bi-graph-up-arrow text-success" style="font-size: 3rem;"></i>
                        </div>
                        <h4 class="feature-title mt-24">Tiempo Real</h4>
                        <p class="text-sm mb-0">
                            Las notificaciones y los eventos se muestran en tiempo lo
                            que permite conocer en todo momento el estado de cada
                            dispositivo conectado.
                        </p>
                    </div>
                </div>
                <div class="feature text-center is-revealing">
                    <div class="feature-inner">
                        <div class="feature-icon">
                            <i class="bi bi-clock-history text-info" style="font-size: 3rem;"></i>
                        </div>
                        <h4 class="feature-title mt-24">Historial de eventos</h4>
                        <p class="text-sm mb-0">
                            Cada evento generado se queda registro como un historial del
                            dipositivo que puede ser consultado mas tarde por los
                            usuarios con acceso a la informacion
                        </p>
                    </div>
                </div>
                <div class="feature text-center is-revealing">
                    <div class="feature-inner">
                        <div class="feature-icon">
                            <i class="bi bi-cpu text-primary" style="font-size: 3rem;"></i>
                        </div>
                        <h4 class="feature-title mt-24">Hardware robusto</h4>
                        <p class="text-sm mb-0">
                            Desarrollamos dispositivos con chip utilizados altamente en
                            la industria de internet de las cosas, con lo cual
                            garantizamos un buen funcionamiento a nuestros clientes
                        </p>
                    </div>
                </div>
                <div class="feature text-center is-revealing">
                    <div class="feature-inner">
                        <div class="feature-icon">
                            <i class="bi bi-app text-success" style="font-size: 3rem;"></i>
                        </div>
                        <h4 class="feature-title mt-24">Interface amigable</h4>
                        <p class="text-sm mb-0">
                            Nuestros productos estan enfocado para usuarios finales que
                            no desean o no tienen tiempo para realizar configuraciones
                            extensas, nuestra interface es amigable y facil de usar.
                        </p>
                    </div>
                </div>
                <div class="feature text-center is-revealing">
                    <div class="feature-inner">
                        <div class="feature-icon">
                            <i class="bi bi-wrench text-info" style="font-size: 3rem;"></i>
                        </div>
                        <h4 class="feature-title mt-24">Configuracion Rapida</h4>
                        <p class="text-sm mb-0">
                            Instalacion, administracion y monitorio de los dispositivos
                            es facilmente configurable desde la aplicacion, no es
                            necesario contactar con soporte tecnico para poder hacerlo.
                        </p>
                    </div>
                </div>
                <div class="feature text-center is-revealing">
                    <div class="feature-inner">
                        <div class="feature-icon">
                            <i class="bi bi-share text-primary" style="font-size: 3rem;"></i>
                        </div>
                        <h4 class="feature-title mt-24">Facil de compartir</h4>
                        <p class="text-sm mb-0">
                            Pudes compartir facilmente tus dispositivos con los usuarios
                            que deseas con solo buscarlos dentro de la aplicacion,
                            puedes decidir que permisos pueden tener cada uno.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing section">
    <div class="container-sm">
        <div class="pricing-inner section-inner">
            <div class="pricing-header text-center">
                <h2 class="section-title mt-0">Funciones disponibles</h2>
                <p class="section-paragraph mb-0">
                    Actualmente nuestra aplicacion se encuentra en desarrollo y aun
                    no tenemos una version premium, por lo que todas las
                    funcionalidades estan disponibles de manera gratuita para todos
                    nuestros usuarios.
                </p>
            </div>
            <div class="pricing-tables-wrap">
                <div class="pricing-table">
                    <div class="pricing-table-inner is-revealing">
                        <div class="pricing-table-main">
                            <div class="pricing-table-header pb-24">
                                <div class="pricing-table-price">
                                    <span class="pricing-table-price-currency h2">$</span><span
                                        class="pricing-table-price-amount h1">0</span><span
                                        class="text-xs">/month</span>
                                </div>
                            </div>
                            <div class="pricing-table-features-title text-xs pt-24 pb-24">
                                Que obtendras
                            </div>
                            <ul class="pricing-table-features list-reset text-xs">
                                <li>
                                    <span>Puedes agregar hasta 10 dispositivos</span>
                                </li>
                                <li>
                                    <span>Compartir con 5 usuarios</span>
                                </li>
                                <li>
                                    <span>Notificaciones ilimitadas</span>
                                </li>
                                <li>
                                    <span>Puedes crear hasta 5 hogares</span>
                                </li>
                            </ul>
                        </div>
                        <div class="pricing-table-cta mb-8">
                            <a class="button button-primary button-shadow button-block" href="#">Registrarme ahora</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="cta section">
    <div class="container">
        <div class="cta-inner section-inner">
            <h3 class="section-title mt-0">Aun no estas convencido?</h3>
            <div class="cta-cta">
                <a class="button button-primary button-wide-mobile" href="page/contact">Escribenos</a>
            </div>
        </div>
    </div>
</section>
    </main>

    <Footer />
  </div>
</template>

<script>
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Home',
  components: {
    BootstrapIcon,
    Header,
    Footer
  }
};
</script>
