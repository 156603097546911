import { createApp } from 'vue'
import App from './App.vue'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'

import './assets/scss/style.scss';

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: 'http://localhost:3000/api/graphql',
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

createApp(App)
.use(router)
.use(apolloProvider)
.component('BootstrapIcon', BootstrapIcon)
.mount('#app')
