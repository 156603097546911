<template>
  <header class="site-header">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container-fluid">
              <a class="navbar-brand" href="#"><img
                class="header-logo-image"
                src="https://www.reachportones.com/wp-content/uploads/2020/01/LOGO-DEFINITIVO-SIN-ESLOGAN-en-blanco.png"
                alt="Logo"
                style="width: 120px"
              /></a>
              <button class="navbar-toggler" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#navbarSupportedContent" 
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon text-white"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <router-link class="nav-link text-white" aria-current="page" to="/" >Home</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link text-white" to="/about" >Acerca de nosotros</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link text-white" to="/contact" >Contacto</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
    </div>
  </header>
</template>
<script>
export default {
  name: 'Header'
};
</script>